<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warehouse" clearable>
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-input placeholder="手机号" v-model="queryParams.phone" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-input placeholder="司机姓名" v-model="queryParams.realName" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-radio-group v-model="queryParams.b">
                    <el-radio :label="false">仓库司机</el-radio>
                    <el-radio :label="true">未加入仓库司机</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handlerQuery">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb20">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-plus"
                    @click="handleAdd"
                    v-if="$app.haveRole('system:driver:add')"
                    size="mini">新增</el-button>
                <el-button
                    type="danger"
                    plain
                    :disabled="multipleSelection.length<=0"
                    icon="el-icon-delete"
                    v-if="$app.haveRole('system:driver:deletes')"
                    size="mini" @click="handleDeletes">批量删除</el-button>
                <el-button
                        type="primary"
                        plain
                        icon="el-icon-download"
                        v-if="$app.haveRole('system:driver:exportExcel')"
                        size="mini" @click="exportExcel">导出</el-button>
            </el-col>
        </el-row>
        <el-table :data="tableData" v-loading="loading" @selection-change="handleSelectionChange"
                  row-key="guid" :size="$store.getters.size">
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="车号" prop="plat" width="80" align="center"></el-table-column>
            <el-table-column label="车牌号" prop="vehicle" width="140"></el-table-column>
            <el-table-column label="司机姓名" prop="realName" width="150"></el-table-column>
            <el-table-column label="手机号" prop="phone" width="150"></el-table-column>
            <el-table-column label="状态" align="center" width="100">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status === 1" effect="plain" size="small">正常</el-tag>
                    <el-tag v-else type="danger" effect="plain" size="small">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="运费统计" align="center" width="88">
                <template slot-scope="scope">
                    <el-switch :value="scope.row.census === 1" @change="censusSwitchChange(scope.row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="入职日期" prop="entryDate" width="150" align="center"></el-table-column>

            <el-table-column label="保险图片" width="80" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.uploadInsureImg === 1"> √ </span>
                </template>
            </el-table-column>
            <el-table-column label="保单金额" prop="insurePrice" align="center" width="120"></el-table-column>
            <el-table-column label="保险到期" prop="insureDate" width="150"></el-table-column>
            <el-table-column label="保险公司" prop="insureCompany" width="230"></el-table-column>


            <el-table-column label="操作" width="350" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" @click="handleUpdateStatus(scope.row)">{{scope.row.status===1?'禁用':'启用'}}</el-button>
                    <el-button type="text" icon="" @click="handleResetPwd(scope.row)"
                               v-if="$app.haveRole('system:driver:reset')">重置密码</el-button>
                    <el-button type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
                               v-if="$app.haveRole('system:driver:edit')">修改</el-button>
                    <el-button type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
                               v-if="$app.haveRole('system:driver:delete')">删除</el-button>
                    <el-button type="text" @click="writeOffDriver(scope.row)" :disabled="scope.row.writeOff !== 1"
                               v-if="$app.haveRole('system:driver:writeOff')">解除注销</el-button>
                </template>
            </el-table-column>
        </el-table>
        <page-item ref="pageItem" :total="total"></page-item>
        <edit ref="edit"/>
    </div>
</template>

<script>
    import PageItem from '@/components/PageItem'
    import Edit from './edit'
    import {getList,removeDriver, resetPwd , deletes, disable, updateCensus, exportExcel, writeOffDrive} from '@/api/system/driver'

    export default {
        name: "Driver",
        components: {PageItem,Edit},
        data(){
            return{
                queryParams: {
                    phone: undefined,
                    realName: undefined,
                    warehouse: undefined,
                    b: false
                },
                multipleSelection: [],
                tableData: [],
                loading: false,
                exportLoading: false,
                total: 0,
                warehouseOption: this.$store.getters.warehouse
            }
        },
        mounted(){
            // this.getTableData();
        },
        methods: {
            /** 获取表数据 */
            getTableData(){
                if(!this.queryParams.warehouse && !this.queryParams.b){
                    this.$message.error("请选择仓库");
                    return;
                }
                if(this.loading){return;}
                this.loading = true;
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                getList(this.queryParams).then(({code, data})=>{
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            /** 查询按钮 */
            handlerQuery(){
                this.$refs.pageItem.current = 1;
                this.getTableData();
            },
            /** 新增司机 */
            handleAdd(){
                this.$refs.edit.handleAdd();
            },
            /** 重置密码 */
            handleResetPwd(row){
                this.$confirm('你确定要重置该司机账户密码?','提示', {
                    type: "warning"
                }).then(()=>{
                    resetPwd(row.guid).then(({code})=>{
                        if(code === 0)this.$message.success("重置密码成功")
                    })
                }).catch(()=>{})
            },
            censusSwitchChange(row){
                if(row.census === 1){
                    row.census = 2
                }else{
                    row.census = 1
                }
                updateCensus({guid: row.guid, census: row.census})
                .then(({code}) =>{
                    if(code === 0){
                        this.$message.success('操作成功')
                    } else {
                        row.census = row.census === 1?2:1
                    }
                })

            },
            /** 启用/禁用 */
            handleUpdateStatus(row){
                const status = row.status === 1 ? 2: 1;
                this.$confirm('你确定要'+(status===1?'启用':'禁用')+'此司机账户?','提示',{
                    type: "warning"
                }).then(()=>{
                    disable(row.guid, status).then(({code})=>{
                        if(code === 0){
                            this.$message.success("操作成功");
                            row.status = status;
                        }
                    })
                })
            },
            writeOffDriver(row){
              this.$confirm("确定要解除注销？","提示" ,{
                type: "warning"
              }).then(() => {
                writeOffDrive(row.guid).then(({code}) => {
                  if(code === 0){
                    row.writeOff = 0
                    this.$message.success("操作成功")
                  }
                })
              }).catch()
            },
            /** 导出 */
            exportExcel(){
                if(!this.queryParams.warehouse && !this.queryParams.b){
                    this.$message.error("请选择仓库");
                    return;
                }
                if(this.exportLoading){return;}
                this.exportLoading = true
                exportExcel(this.queryParams).then((d)=>{this.exportLoading = false})
            },
            /** 修改 */
            handleUpdate(row){
                this.$refs.edit.handleUpdate(row.guid);
            },
            /** 删除 */
            handleDelete(row){
                this.$confirm('你确定要删除该司机信息?','提示', {
                    type: "warning"
                }).then(()=>{
                    removeDriver(row.guid).then(({code})=>{
                        if(code === 0){
                            this.$message.success("删除成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            /** 批量删除 */
            handleDeletes(){
                this.$confirm('你确定要删除选中司机信息','提示',{
                    type: "warning"
                }).then(()=>{
                    if(this.multipleSelection.length<=0){
                        this.$message.error("未选择需要删除的司机")
                    } else {
                        let data = this.multipleSelection.map(item => {return item.guid})
                        deletes(data).then(({code})=>{
                            if(code === 0 ){
                                this.$message.success("批量删除成功");
                                this.getTableData();
                            }
                        })
                    }
                })
            },
            handleSelectionChange(val){
                this.multipleSelection = val
            }
        }
    }
</script>

<style scoped>

</style>
